/*
   master.scss
   www.jrignacio.com

   Created by JR Ignacio.
   Copyright 2014 JR Ignacio. All rights reserved.
*/

body {
  background: #454545 url(../img/greynoise.png);
  background: #d8e7e7;
  margin: 0px;
  padding: 0px;
  font-family: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  color: #000;
}
#container {
  margin: 0;
  padding: 0 0.2em;
}
#content {
  margin: 8em auto 0;
  font-size: 16px;
  text-align: center;
  padding: 0 0 2em;
}
#nameplate {
  font-size: 0.85em;
  padding-bottom: 0.5em;
  line-height: 1.5em;
}
#contactplate {
  padding-top: 0.5em;
  font-size: 1.1em;
  line-height: 1.5em;
  color: #dfdfdf;
}
h1 {
  color: #000;
  font-weight: normal;
  font-size: 2.3em;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  padding: 0;
  margin: 0;
  text-decoration: none;
  line-height: 1.6em;
}

h1 {
  &.quote {
    font-family: Georgia;
    font-weight: bold;
    text-align: center;
    color: #f0f0f0;
    padding-top: 25%;
  }
}

p {
  &.quote {
    font-family: Georgia;
    font-size: 1.5em;
    text-align: center;
  }
}

div {
  font-size: 2.4em;
}

#copyright {
  padding-top: 2em;
  font-size: 14px;
  text-align: center;
  line-height: 14px;
}
a {
  &, &:link, &:visited {
    $link-color: rgb(100, 100, 100);
    font-weight: 500;
    color: $link-color;
    text-decoration: none;
    padding: 0.6em 0.7em;
    border-radius: 0.7em;
    &.link-border {
      border: 1px solid $link-color;
    }
    margin: 0.3em 0.3em;
    display: inline-block;
    &.link-logo {
      padding: 0.2em;
      margin: 0;
      font-size: 2.5em;
    }
  }
  &:hover {
    // color: rgb(0, 136, 255);
    // border-color: rgb(0, 136, 255);
    color: rgb(100, 100, 100);
    &.link-logo {
      color: rgb(20, 20, 20);
    }
  }
}

footer{
  div {
    font-size: 0.8em;
    margin-top: 2em;
    font-weight: 400;
  }
}

small {
  font-size: 0.4em;
}
